<template>
  <header>
    <div class="app-header" fixed="top">
      <div class="app-logo-container"  @click="this.$router.push({ name: 'Home' })">
        <img src="static/msm_loop_logo_transparent.png" class="app-logo" />
      </div>
      <div class="nav-right">
        <div class="white-hover">
          <span @click="this.$router.push({ name: 'imprint' })">Impressum</span>
        </div>

        <div class="white-hover">
          <span @click="this.$router.push({ name: 'dataprotection' })">Datenschutz</span>
        </div>

        <div class="logout white-hover" @click="logout">
          <img src="@/assets/logout.svg" class="logout_img" />
          <span class="logout_text">{{ lang.logout }}</span>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import router from "../router/index.js";
import AuthenticationService from "@/services/AuthenticationService";

export default {
  Name: "Header",
  data() {
    return {
      path: router.currentRoute.value.path,
      lang: {
        logout: "Abmelden",
      },
    };
  },
  methods: {
    logout() {
      AuthenticationService.logout();
      if (this.isExternalUser) this.$router.push({ name: "signedout" });
    },
  },
};
</script>

<style scoped>
.app-header {
  height: 4vh;
  padding: 0;
  margin: 0;
  background-color: #4472c4;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.app-logo-container {
  cursor: pointer;
  height: 4vh;
  display: block;
}
.app-logo {
  background-color: #fff;
  /* width: 180px; */
  height: 100%;
  max-width: none;
  border: 0;
  vertical-align: middle;
  object-fit: contain;
}
.white-hover {
  cursor: pointer;
  color: #000000 !important;
  filter: invert(100%) sepia(1%) saturate(1079%) hue-rotate(175deg) brightness(93%) contrast(93%) !important;
}
.white-hover:hover {
  filter: invert(96%) sepia(0%) saturate(17%) hue-rotate(309deg) brightness(105%) contrast(105%) !important;
}
.logout {
  font-size: 2.5vh;
  /* position: absolute; */
  /* right: 1em; */
  /* background-color: #fff; */
  display: flex;
  flex-direction: row;
  width: auto;
  padding: 0.2em;
}
.logout_text {
  padding-left: 1vh;
}
.logout_img {
  width: 25px;
}
.nav-right {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 20%;
  margin-right: 10px;
}
</style>