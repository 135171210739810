<template>
  <div id="dataprotection" class="animated fadeIn">
    <h2>Privacy policy</h2>

    <h5>Collecting general information</h5>

    <p>When you enter our website, information of a general nature is automatically collected. This information (server logs)
    concerns, for example, the type of web browser, operating system used, domain names of the Internet service provider
    which are used by the user, etc. This only applies to information which does not allow the user to be identified.
    This information is necessary from a technical point of view in order to provide the user with the desired content
    of the websites and transferring of this information happens inevitably while using the Internet. We analyze this type
    of anonymous information for statistical purposes in order to optimize our website and technical issues which are
    related to it.</p>
    
    <h5>Registration on our website</h5>

    <p>In order to use our personalized services, some personal data is collected during the registration, for example:
    surname, address, as well as contact and communication details, such as phone number and e-mail address. If a user
    is registered at our website, he has access to the content and services which are offered only to registered users.
    In addition, users who are logged-in have the possibility of changing or deleting the data provided during registration
    at any time. Of course, we can provide the user with information about his personal data that have been stored by us.
    If it is not contrary to statutory obligations regarding data storage, we will be happy to correct or delete this data
    at the user's request. In order to contact us regarding this matter, please use the contact details provided at the
    end of this privacy policy.</p>
    <p>In order to ensure the user's data security during transmission, we use encryption procedures which correspond with
    the current state of the art (e.g. SSL) via HTTPS.</p>
    
    <h5>Data deletion or blocking</h5>
    
    <p>We comply with the data reduction and minimization principle, therefore we only store the user's personal data for
    as long as it is necessary in order to achieve the goals set out in this document, or in a manner that is compliant
    with the statutory data storage periods. When the target ceases to be current or when these periods expire, the
    corresponding data is blocked or deleted routinely and in compliance with statutory provisions.</p>
    
    <h5>’Cookie’ files</h5>
    <p>Similarly to many other websites, we use the so-called 'cookie' files. These are small text files sent from the server to
    the user's hard drive. In this way, we automatically receive certain data through the user's computer and its connection
    to the Internet, e.g. IP address, used browsers, operating system.</p>
    <p>'Cookie' files cannot be used to open programs or transfer viruses onto a computer. Based on the information contained
    in the 'cookie' files, we can facilitate the user's navigation and make it possible to display our websites properly.</p>
    <p>There is no case in which the data registered by us is transferred to third parties, nor is there any connection with
    personal data being created without the consent of the user.</p>
    <p>Of course, the user can, as a rule, browse our site without using the 'cookie' files. Web browsers are usually set
    in such a way that he accepts the 'cookie' files. The user may disable the use of 'cookie' files at any time by changing
    the settings of his browser. To learn how to change these settings, use the help function of your web browser. Keep in
    mind that in case of the 'cookie' files being disabled, it is possible that particular functions of our website may
    not work correctly.</p>
    
    <h5>The right to information, correction, blocking, deletion and objection</h5>
    <p>The user has the right to receive information about his personal data being saved with us at any time. The user also
    has the right to correct, block, or – regardless of saving data for the purpose of realizing a transaction - to delete
    his personal data. For this purpose, please contact our privacy policy representative, whose contact details can be
    found at the end of this document.</p>
    <p>In order to be able to apply the blocking procedure at any time, this data must be stored in a blocking file for
    monitoring purposes. It is also possible to request deletion of the data, unless there is a statutory obligation
    to archive them. If such an obligation exists, we block a user's data at his request.</p>
    <p>It is possible to make changes or cancel the consent by informing us about it (with the future effect).</p>
    
    <h5>Changes of the privacy policy</h5>
    <p>We reserve the right to adjust our privacy policy from time to time in order to comply with current statutory
    requirements or to implement changes regarding our services, e.g. when introducing new services. Then, in case of
    a new visit, a new and updated privacy policy applies.</p>
    
    <h5>Questions for the privacy policy representative</h5>
    <p>In case of any questions regarding the privacy policy, please write to us an e-mail message or directly contact our
    privacy policy representative.</p>
    
    <h5>Name and address of the data protection officer</h5>
    <p>Sebastian Heining-Boes<br/>
    <b>BOLD</b> Solution GmbH<br/>
    An der Alten Ziegelei 34<br/>
    DE-48157 Münster<br/>
    <a href="mailto:gdpr@boldsolution.com" title="gdpr@msm-group.com">gdpr@boldsolution.com</a></p>

  </div>
</template>

<script>
export default {
  name: 'DataProtection'
}
</script>

<style scoped>
  #dataprotection {
    text-align: left;
    margin: 10px;
  }
  h5 {
    margin-top:1em
  }
</style>