<template>
  <div>
    <AppHeader class="app-header" />
    <router-view />
  </div>
</template>

<script>
import AppHeader from "./components/Header.vue";

export default {
  name: "app",
  components: { AppHeader },
};
</script>

<style>
@import "~bootstrap-vue/dist/bootstrap-vue.css";

#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background-color: #e4e5e6;
}

body {
  padding: 0 !important;
  margin: 0 !important;
}

.filterbar {
  margin: 0px !important;
  padding: 0px !important;
  text-align: center;
}

.nopadding {
  padding: 0px !important;
  margin: 0px !important;
}
.text-center {
  text-align: center;
}
.sticky-header {
  position: sticky;
  top: 0;
  background: white;
  white-space: nowrap;
}
.sticky-filter {
  position: sticky;
  background: white;
}
.app-header {
  position: sticky;
}
@media screen and (max-width: 768px) {
  .hide-on-mobile {
    display: none !important;
  }
}
</style>
